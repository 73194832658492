/**
 * @ The external dependecies.
 */
import { handleActions } from 'redux-actions';

import {
    clone,
    sortBy,
    compose,
    prop
} from 'ramda';

/**
 * @ The internal dependecies.
 */
import { appendArray, updateObject } from '../../../lib/utility';
import { receiveTableTopConfig } from '../app/actions';
import { receiveTableCheckResponse } from '../tablecheck/actions';
import { loyaltyLogOut } from '../loyalty/actions';
import { MODAL_SUGGESTIONS } from 'lib/constants';
import { getOfflineProducts } from './selectors';
import * as features  from '../features/selectors';
import * as Analytics from '../../../lib/analytics-ga4';

/**
 * @ The actions.
 */
import {
    clearCart,
    clearCartPartial,
	addToCart,
	reciveCartData,
	removeFromCart,
	updateModifiers,
	updateCartProduct,
	updateCartProducts,
	setEditProduct,
    clearEditProduct,
    deliveryUpdate, 
    donationAmountUpdate,
    promotionUpdate, 
    tipAmountUpdate,
    tipPercentageUpdate,
    acknowledgeAlcohol,
    donationsModalShown, 
    tipsModalShown,
    updatePrevAddons,
    updateCustomerDetails, 
    updateEmail,
    updatePhone,
    updateEmployeeID,
    receiveOrderConfirmationData,
    updatePaymentDetails,
    updateSpreedlyTransactionToken,
    updateMode,
    updateName,
    updateSplitAmount,
    updateTableData,
    updateLoyaltyAmount
} from './actions';

import {
    openModal
} from '../ui/actions';

import {
    showSuggestionsModal,
    receiveTableTopData
} from '../app/actions';

import {
    endRequestWithError
} from '../requests/actions';

import { addLoadedValueAccountNumberToCart, removeLoadedValueAccountNumberFromCart } from '../loadedvalueaccount/actions'


/**
 * The cart json object template.
 *
 * @type {Object}
 */
const cartDataTemplate = {
    kobp: "",
    airportIdent: "",
    storeID: "2826",
    storeWaypointID: "",
    storeWaypointDescription: "",
    storeName: "",
    cartToken: "",
    clientToken: "",
    btCardTokenOrRewardAccount: "",
    bCreditCardTransaction: true,
    bHoldOrder: false,
    totalCost: 0.00,
    donationAmount: 0, 
    donationReceiptMessage: null, 
    tipAmount: -1.000,
    originalTipPercentage: -1.000,
    tipPercentage: -1.000,
    originTimeLocal: "", 
    deliveryNotesForOrder: "",
    deliveryHandlingTimeMessage: "",
	deliverySelected: false,
    deliveryLocationForOrder: "",
    email: "",
    arrCart: [],
    prevUpsellItems: [],
    prevAddonItems: [],
    waypointOrderLocation: '', //tableId
    isVirtualKiosk: false,
    employeeNumber: '',
    alcoholAcknowledged: false,
    donationsModalShown: false, 
    tipsModalShown: false,
    urlPOSID: "",
    urlTableNumber: "",
    /*tableTentNumber: "",*/
    useHubUpsellAddon: true,
    oatEmployeeID: "",
    guestCustomerDetails: {
        firstName: "",
        lastName: "",
        mobileNumber: "",
        mobileNumberCountry: "",
        enableTextNotifications: false, 
        marketingOptIn: false
    },
    loadedValueAccountNumber: null,
    mode: '',
    splitAmount: null, 
    loyaltyAmount: 0
}

/**
 * @ The reducer.
 */
const defaultState = {
    data: {
        ...cartDataTemplate
    }, //persisted to local storage
	editableProduct: null,
	cartPricingData: null,
	modifiers: {
		tip: 0,
		discount: 0,
		taxRate: 0
    },
    showSuggestionsModal: false,
    showSuggestionsModalPending: false,
    validTableNumber: false,
    invalidTableNumber: false,
    retryCartUpdate: false
}

/**
 * The actions Handler.
 *
 * @type {Function}
 */

const onReceiveTableCheckResponse = (state, action) => {
    if (action.payload.success) {
        const cartData = updateObject(state.data, { waypointOrderLocation: action.payload.locationID, urlTableNumber: action.payload.locationName, isVirtualKiosk: action.payload.isVirtualKiosk, employeeNumber: action.payload.employeeNumber });
        return updateObject(state, {
            data: cartData
        });
    }
    else {
        const cartData = updateObject(state.data, { waypointOrderLocation: '', employeeNumber: '', isVirtualKiosk: false });
        return updateObject(state, {
            data: cartData
        });
    }
}

const onUpdateDelivery = (state, action) => {
    const cartData = updateObject(state.data, { deliverySelected: action.payload && action.payload.deliverySelected, 
                                                deliveryLocationForOrder: action.payload && action.payload.deliveryLocationForOrder, 
                                                deliveryNotesForOrder: action.payload && action.payload.deliveryNotesForOrder });
    return updateObject(state, {
        data: cartData
    });
}

const onUpdateDonationAmount = (state, action) => {
    const receiptMsg = action.payload && action.payload.Charities && action.payload.Charities.Charity &&
                       action.payload.Charities.Charity.length > 0 && action.payload.Charities.Charity[0] &&
                       action.payload.Charities.Charity[0] ? `${action.payload.Charities.Charity[0].ThankYouMessage} Please visit 
                                                              ${action.payload.Charities.Charity[0].Url} for further information.` : null;
    const cartData = updateObject(state.data, { donationAmount: action.payload && action.payload.selectedAmount ? action.payload.selectedAmount : 0, 
                                                donationReceiptMessage: receiptMsg });
    return updateObject(state, {
        data: cartData
    });
}

const onUpdatePromotion = (state, action) => {
    if (!action.payload) { 
        const cartData = updateObject(state.data, { email: '' });
        delete cartData.promotion;

        return updateObject(state, { data: cartData });
    }
    const cartData = updateObject(state.data, { promotion: action.payload });
    return updateObject(state, { data: cartData });
}

const onUpdateTipAmount = (state, action) => {
    const cartData = updateObject(state.data, { tipAmount: action.payload, tipPercentage: -1.000 });
    return updateObject(state, {
        data: cartData
    });
}

const onUpdatePaymentDetails = (state, action) => {
    const cartData = updateObject(state.data, { ...action.payload });
    return updateObject(state, {
        data: cartData
    });
}

const onUpdateSpreedlyTransactionToken = (state, action) => {
    const spreedlySession = updateObject(state.data.SpreedlySession,
        {
            TransactionToken: action.payload
        });

    const cartData = updateObject(state.data, { SpreedlySession: spreedlySession });

    return updateObject(state, {
        data: cartData
    });
}


const onUpdateTipPercentage = (state, action) => {
    const cartData = updateObject(state.data, { tipAmount: -1.000, tipPercentage: action.payload });
    return updateObject(state, {
        data: cartData
    });
}

const onUpdateEmployeeID = (state, action) => {
    const cartData = updateObject(state.data, { oatEmployeeID: action.payload });
    return updateObject(state, {
        data: cartData
    });
}

const onReciveCartData = (state, action) => {
    const payload = action.payload;

    const itemsOffline = getOfflineProducts(payload);

    if (itemsOffline.length > 0 && features.retryCartUpdateForOfflineItemsEnabled()) {
        return updateObject(state, {
            data: {
                ...state.data,
                arrCart: state.data.arrCart.filter((value, index, array) => !itemsOffline.includes(value.inventoryItemID)),
                cartToken: payload.cartToken,
                clientToken: payload.clientToken
            },
            showSuggestionsModalPending: false,
            retryCartUpdate: true
        });
    }
    else {
        return updateObject(state, {
            cartPricingData: payload,
            data: {
                ...state.data,
                arrCart: state.data.arrCart.filter((value, index, array) => !itemsOffline.includes(value.inventoryItemID)),
                tipAmount: payload.tipPercentage > 0 ? -1.00 : payload.tipAmount, //Roundtrip tipAmount. Server never returns -1.00.
                tipPercentage: payload.tipAmount > 0 ? -1.000 : payload.tipPercentage > 0 ? payload.tipPercentage : -1.000,  //Roundtrip tipPercentage. Server never returns -1.000.
                totalCost: payload.orderTotal,
                cartToken: payload.cartToken,
                clientToken: payload.clientToken, 
                donationsModalShown: payload.donationAmount && payload.donationAmount > 0 ? true : state.data.donationsModalShown, 
                tipsModalShown: payload.tipPercentage > 0 || payload.tipAmount > 0 ? true : state.data.tipsModalShown,
                loadedValueAccountNumber: payload.loadedValueAccountNumber
            },
            showSuggestionsModalPending: false,
            showSuggestionsModal: (state.showSuggestionsModalPending && payload.upsaleItems && payload.upsaleItems.length > 0) ? new Date() : null,
            retryCartUpdate: false
        });
    }
}

const onReceiveTableTopData = (state, action) => {
    var productsDict = action.payload.inventoryItemMains.reduce((obj, product) => { obj[product.inventoryItemID] = product; return obj }, {});

    var updatedArrCart = state.data.arrCart.map(cartItem => {

        if (productsDict.hasOwnProperty(cartItem.inventoryItemID)) {
            cartItem.inventoryMainOptionChoice.choices.forEach((choice) => {
                sortBy(compose(parseInt, prop('choiceOrder')), choice.inventoryChoices);
            });
            cartItem.inventoryMainOptionChoice.options.forEach((option) => {
                sortBy(compose(parseInt, prop('optionOrder')), option.inventoryOptions);
            });
            var selectedSizes = cartItem.inventoryItemSubs.filter(x => x.selected).map(x => x.inventoryItemSubID);

            var localizedProduct = clone(productsDict[cartItem.inventoryItemID]);
            localizedProduct.inventoryMainOptionChoice.choices.forEach((choice) => {
                sortBy(compose(parseInt, prop('choiceOrder')), choice.inventoryChoices);
            });
            localizedProduct.inventoryMainOptionChoice.options.forEach((option) => {
                sortBy(compose(parseInt, prop('optionOrder')), option.inventoryOptions);
            });

            var updatedCartItem = {
                ...cartItem,
                inventoryItemName: localizedProduct.inventoryItemName,
                inventoryItemDescription: localizedProduct.inventoryItemDescription,
                inventoryItemSubs: localizedProduct.inventoryItemSubs.map(x => { x.selected = selectedSizes.includes(x.inventoryItemSubID); return x }),
                inventoryMainOptionChoice: {
                    choices: localizedProduct.inventoryMainOptionChoice.choices.map((choice) => {
                        var cartItemChoice = cartItem.inventoryMainOptionChoice.choices.find(c => c.choiceID === choice.choiceID);
                        if (cartItemChoice) {
                            choice.inventoryChoices.forEach((innerChoice, i) => {
                                if (cartItemChoice.inventoryChoices.length > i) {
                                    innerChoice.selected = cartItemChoice.inventoryChoices[i].selected;
                                }
                            })
                        }
                        return choice;
                    }),
                    options: localizedProduct.inventoryMainOptionChoice.options.map((option) => {
                        var cartItemOption = cartItem.inventoryMainOptionChoice.options.find(c => c.optionID === option.optionID);
                        if (cartItemOption) {
                            option.inventoryOptions.forEach((innerOption, i) => {
                                if (cartItemOption.inventoryOptions.length > i) {
                                    innerOption.selected = cartItemOption.inventoryOptions[i].selected;
                                }
                            })
                        }
                        return option;
                    })
                }
            }

            return updatedCartItem;
        }
        else {
            return cartItem;
        }
    });

    return updateObject(state, {
        data: {
            ...state.data,
            arrCart: updatedArrCart
        }
    });
}

const onReceiveOrderConfirmationData = (state, action) => {
    const payload = action.payload;

    const itemsOffline = getOfflineProducts(payload);

    return updateObject(state, {
        data: {
            ...state.data,
            arrCart: state.data.arrCart.filter((value, index, array) => !itemsOffline.includes(value.inventoryItemID))
        },
    });
}

const onUpdateCustomerDetails = (state, action) => {
    const guestCustomerDetails = updateObject(state.data.guestCustomerDetails, {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName, 
        mobileNumber: action.payload.mobileNumber,
        mobileNumberCountry: action.payload.mobileNumberCountry, 
        enableTextNotifications: action.payload.enableTextNotifications, 
        marketingOptIn: action.payload.marketingOptIn
    });

    const cartData = updateObject(state.data, { guestCustomerDetails: guestCustomerDetails, email: action.payload.email});

    return updateObject(state, {
        data: cartData
    });
}

const onUpdateEmail = (state, action) => {
    const guestCustomerDetails = updateObject(state.data.guestCustomerDetails,
        {
            mobileNumberCountry: '',
            mobileNumber: '',
            enableTextNotifications: false
        });

    const cartData = updateObject(state.data, { guestCustomerDetails: guestCustomerDetails, email: action.payload});

    return updateObject(state, {
        data: cartData
    });
}

const onUpdatePhone = (state, action) => {
    const guestCustomerDetails = updateObject(state.data.guestCustomerDetails,
        {
            mobileNumberCountry: action.payload.phoneCountry,
            mobileNumber: action.payload.phone,
            enableTextNotifications: true
        });

    const cartData = updateObject(state.data, { guestCustomerDetails: guestCustomerDetails, email: '' });
    return updateObject(state, {
        data: cartData
    });
}

const onAcknowledgeAlcohol = (state, action) => {
    const cartData = updateObject(state.data, { alcoholAcknowledged: true });
    return updateObject(state, {
        data: cartData
    });
}

const onAddToCart = (state, action) => {
    let arrCart = state.data.arrCart;

    for (const product of action.payload.products) {
        //arrCart = [...arrCart.slice(0, arrCart.findIndex(obj => obj.inventoryItemID === product.inventoryItemID)),
        //    product,
        //    ...arrCart.slice(arrCart.findIndex(obj => obj.inventoryItemID === product.inventoryItemID))];
        arrCart = [...arrCart,
            product];
    }

    const cartData = updateObject(state.data, { arrCart: arrCart });

    return updateObject(state, {
        data: cartData
    });
}

const onOpenModal = (state, action) => {
    if (action.payload.type === MODAL_SUGGESTIONS && action.payload.data.upsaleItems.length > 0) {
        const prevUpsellItems = appendArray(state.data.prevUpsellItems, action.payload.data.upsaleItems);
        const cartData = updateObject(state.data, { prevUpsellItems: prevUpsellItems });
        return updateObject(state, {
            data: cartData
        });
    }
    return state;
}

const onReceiveTableTopConfig = (state, action) => {
    const cartData = updateObject(state.data, {
        kobp: window.kobp,
        airportIdent: action.payload.airportIdent,
        storeWaypointID: action.payload.storeWaypointID,
        storeWaypointDescription: action.payload.storeWaypointDescription,
        storeName: action.payload.storeName,
        originalTipPercentage: action.payload.tippingAutoAdd ? action.payload.tippingAutoPercentage : -1.000,
        storeID: action.payload.storeID,
        urlPOSID: window.posID,
        useHubUpsellAddon: true
    });

    return updateObject(state, {
        data: cartData
    });
}

const onClearCart = (state, action) => {
    const cartData = updateObject(state.data, {
        cartToken: '',
        totalCost: 0.00,
        email: '',
        donationAmount: 0, 
        donationReceiptMessage: null, 
        deliveryNotesForOrder: "",
        deliveryHandlingTimeMessage: "",
        deliverySelected: false,
        deliveryLocationForOrder: "",
        tipAmount: -1.00,
        tipPercentage: state.data.originalTipPercentage,
        arrCart: [],
        prevUpsellItems: [],
        prevAddonItems: [],
        waypointOrderLocation: '',
        isVirtualKiosk: false,
        employeeNumber: '',
        urlTableNumber: "",
        alcoholAcknowledged: false,
        donationsModalShown: false, 
        tipsModalShown: false,
        oatEmployeeID: "",
        guestCustomerDetails: {
            firstName: "",
            lastName: "",
            mobileNumber: "",
            mobileNumberCountry: "",
            enableTextNotifications: false, 
            marketingOptIn: false
        },
        clientToken: '',
        loadedValueAccountNumber: null,
        customerName: '',
        mode: '',
        splitAmount: null,
        loyaltyAmount: 0, 
        adyenSession: null
    });
    if (cartData.promotion) delete cartData.promotion;

    return updateObject(state, {
        data: cartData,
        cartPricingData: null,
        retryCartUpdate: false
    });
}

//Clears everything except waypointOrderLocation(tableId)
const onClearCartPartial = (state, action) => {
    const cartData = updateObject(state.data, {
        cartToken: '',
        email: '',
        totalCost: 0.00,
        donationAmount: 0, 
        donationReceiptMessage: null, 
        deliveryNotesForOrder: "",
        deliveryHandlingTimeMessage: "",
        deliverySelected: false,
        deliveryLocationForOrder: "",
        tipAmount: -1.00,
        tipPercentage: state.data.originalTipPercentage,
        arrCart: [],
        prevUpsellItems: [],
        prevAddonItems: [],
        alcoholAcknowledged: false,
        donationsModalShown: false, 
        tipsModalShown: false,
        oatEmployeeID: "",
        guestCustomerDetails: {
            firstName: "",
            lastName: "",
            mobileNumber: "",
            mobileNumberCountry: "",
            enableTextNotifications: false, 
            marketingOptIn: false
        },
        clientToken: '',
        customerName: '',
        loadedValueAccountNumber: null,
        splitAmount: null,
        loyaltyAmount: 0
    });

    return updateObject(state, {
        data: cartData,
        cartPricingData: null,
        retryCartUpdate: false
    });
}

const onDonationsModalShown = (state, action) => {
    const cartData = updateObject(state.data, {
        donationsModalShown: true
    });

    return updateObject(state, {
        data: cartData
    });
}

const onTipsModalShown = (state, action) => {
    const cartData = updateObject(state.data, {
        tipsModalShown: true
    });

    return updateObject(state, {
        data: cartData
    });
}

const onShowSuggestionsModal = (state, action) => {
    return updateObject(state, {
        showSuggestionsModalPending: true
    });
}

const onEndRequestWithError = (state, action) => {
    //if (action.payload.id === `'${startCartUpdate}'`) {
    //    return updateObject(state, {
    //        showSuggestionsModalPending: false
    //    });
    //}

    return state;
}

const onAddLoadedValueAccountNumberToCart = (state, action) => {
    const cartData = updateObject(state.data, { loadedValueAccountNumber: action.payload.accountNumber });
    return updateObject(state, {
        data: cartData
    });
}

const onRemoveLoadedValueAccountNumberFromCart = (state, action) => {
    const cartData = updateObject(state.data, { loadedValueAccountNumber: null });
    return updateObject(state, {
        data: cartData
    });
}

const onUpdateName = (state, action) => {
    const cartData = updateObject(state.data, { customerName: action.payload });
    return updateObject(state, {
        data: cartData
    });
}

const onUpdateMode = (state, action) => {
    return updateObject(state, {
        mode: action.payload
    });
}

const onUpdateSplitAmount = (state, action) => {
    const cartData = updateObject(state.data, { splitAmount: action.payload });
    return updateObject(state, {
        data: cartData
    });
}

const onUpdateTableData = (state, action) => {
    const cartData = updateObject(state.data, { waypointOrderLocation: action.payload.tableId, urlTableNumber: action.payload.tableNumber });
    
    return updateObject(state, {
        data: cartData
    });
}

const onLoyaltyLogOut = (state, action) => {
    const cartData = updateObject(state.data, {
        loyaltyAmount: 0
    });

    return updateObject(state, {
        data: cartData
    });
}

const onUpdateLoyaltyAmount = (state, action) => {
    const cartData = updateObject(state.data, {
        loyaltyAmount: action.payload
    });

    return updateObject(state, {
        data: cartData
    });
}

const cart = handleActions({
    [updateSpreedlyTransactionToken]: (state, action) => onUpdateSpreedlyTransactionToken(state, action),
    [receiveTableCheckResponse]: (state, action) => onReceiveTableCheckResponse(state, action),
    [clearCart]: (state, action) => onClearCart(state, action),
    [donationsModalShown]: (state, action) => onDonationsModalShown(state, action), 
    [tipsModalShown]: (state, action) => onTipsModalShown(state, action),
    [clearCartPartial]: (state, action) => onClearCartPartial(state, action),
	[setEditProduct]: (state, { payload }) => ({
		...state,
		editableProduct: payload
	}),

	[clearEditProduct]: (state) => ({
		...state,
		editableProduct: null
	}),

	[updateCartProducts]: (state, { payload }) => ({
		...state,
		data: {
			...state.data,
			arrCart: payload
		}
	}),
    [showSuggestionsModal]: (state, action) => onShowSuggestionsModal(state, action),
	[updateCartProduct]: (state, { payload : {
		productIndex,
		productUpdates
	}}) => ({
		...state,
		data: {
			...state.data,
			arrCart: state.data.arrCart.map((product, index) => {
				if ( index !== productIndex ) {
					return product;
				}

				return {
					...product,
					...productUpdates
				}
			})
		}
        }),
    [openModal]: (state, action) => onOpenModal(state, action),
    [deliveryUpdate]: (state, action) => onUpdateDelivery(state, action), 
    [donationAmountUpdate]: (state, action) => onUpdateDonationAmount(state, action), 
    [promotionUpdate]: (state, action) => onUpdatePromotion(state, action), 
    [tipAmountUpdate]: (state, action) => onUpdateTipAmount(state, action),
    [tipPercentageUpdate]: (state, action) => onUpdateTipPercentage(state, action),

	[updateModifiers]: (state, { payload }) => ({
        ...state,

		modifiers: {
			...state.modifiers,
			...payload
		}
	}),

    [addToCart]: (state, action) => onAddToCart(state, action),

	[removeFromCart]: (state, { payload }) => {
        // const removeditem = state.data.arrCart[payload];
        // if (payload.ga) Analytics.logRemoveFromCart(removeditem);
        console.log('payload', payload)

        return ({
            ...state,
            data: {
                ...state.data,
                tipAmount: state.data.arrCart.length > 1 ? state.data.tipAmount : -1.00,
                arrCart: state.data.arrCart.filter((item, index) => !payload.includes(index))
            }
        })
},
    [receiveTableTopConfig]: (state, action) => onReceiveTableTopConfig(state, action),
    [acknowledgeAlcohol]: (state, action) => onAcknowledgeAlcohol(state, action),
    [updateCustomerDetails]: (state, action) => onUpdateCustomerDetails(state, action), 
    [updateEmail]: (state, action) => onUpdateEmail(state, action),
    [updatePhone]: (state, action) => onUpdatePhone(state, action),
    [updateEmployeeID]: (state, action) => onUpdateEmployeeID(state, action),
    [updatePaymentDetails]: (state, action) => onUpdatePaymentDetails(state, action),
    [reciveCartData]: (state, action) => onReciveCartData(state, action),
    [endRequestWithError]: (state, action) => onEndRequestWithError(state, action),
    [receiveOrderConfirmationData]: (state, action) => onReceiveOrderConfirmationData(state, action),
    [receiveTableTopData]: (state, action) => onReceiveTableTopData(state, action),
    [updatePrevAddons]: (state, { payload }) => ({
        ...state,
        data: {
            ...state.data,
            prevAddonItems: payload.prevAddonItems
        }
    }),
    [addLoadedValueAccountNumberToCart]: (state, action) => onAddLoadedValueAccountNumberToCart(state, action),
    [removeLoadedValueAccountNumberFromCart]: (state, action) => onRemoveLoadedValueAccountNumberFromCart(state, action),
    [updateMode]: (state, action) => onUpdateMode(state, action), 
    [updateName]: (state, action) => onUpdateName(state, action),
    [updateSplitAmount]: (state, action) => onUpdateSplitAmount(state, action),
    [updateTableData]: (state, action) => onUpdateTableData(state, action),
    [loyaltyLogOut]: (state, action) => onLoyaltyLogOut(state, action),
    [updateLoyaltyAmount]: (state, action) => onUpdateLoyaltyAmount(state, action)
}, defaultState);

export default cart;
