import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import PropTypes from 'prop-types';

import { requestAdyenPaymentMethods } from '../../store/state/adyen/actions';
import { getAdyenCardDetails } from './payment-helper';

const AdyenDropIn = ({ amount, currencyCode, countryCode, handlePaymentMethod }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const urlParams = new URLSearchParams(window.location.search);
    const configuration = useSelector(state => state.adyen.configuration);
    const checkout = useSelector(state => state.checkout);

    useEffect(() => {
        // dispatch(clearAdyenSession());
        // get adyen payment methods
        dispatch(requestAdyenPaymentMethods({
            waypointid: window.waypointID, currencyCode: currencyCode, totalAmount: amount * 100, countryCode: countryCode, languageCode: `${window.language}-${countryCode}`
        }));
    }, []);
    useEffect(() => {
        if (urlParams) {
            const redirect = urlParams.get('redirectResult');
            if (redirect && checkout && checkout.adyenSession) {
                const session = {...checkout.adyenSession};
                session.AdyenRequestPayload = { details: { redirectResult: redirect } };
                session.AdyenResponsePayload = '';

                handlePaymentMethod(getAdyenCardDetails(session));
                window.isMarketplace ? history.push(`/marketplace/${window.marketplaceId}/${window.waypointID}/checkout-complete`) : history.push('/checkout-complete');
            }
        }
    }, [urlParams])
    
    return (
        <div className='adyen-dropin-container'>
            { !configuration ?
                <div></div> :
                <Checkout configuration={configuration} amount={amount} currencyCode={currencyCode} countryCode={countryCode} handlePaymentMethod={handlePaymentMethod} adyenSession={checkout && checkout.adyenSession} />
            }
        </div>
    )
}
const Checkout = ({ configuration, amount, currencyCode, countryCode, handlePaymentMethod, adyenSession }) => {
    const history = useHistory();
    const paymentContainer = useRef(null);

    useEffect(() => {
        if (configuration) {
            console.log('checkout configuration info', configuration)

            const createCheckout = async () => {
                const fixedAmount = Math.round(amount * 100);
                const applePayConfiguration = {
                    amount: { value: fixedAmount, currency: currencyCode },
                    countryCode: countryCode
                };
                const checkout = await AdyenCheckout({
                    ...configuration, 
                    onSubmit: (state, dropin) => {
                        console.log('adyen checkout state', state)
                        // console.log('adyen checkout dropin', dropin)
                        if (state.data) {
                            state.data.amount = { currency: currencyCode, value: fixedAmount };
                            state.data.reference = '';
                            state.data.additionalData = { "allow3DS2": true };
                            state.data.returnUrl = window.location.href

                            const session = {
                                AdyenRequestPayload: state.data, 
                                AdyenResponsePayload: '', 
                                Reference: ''
                            }
                            handlePaymentMethod(getAdyenCardDetails(session));
                        }
                    }, 
                    onAdditionalDetails: (state, dropin) => {
                        console.log('adyen additional details state', state)
                        const session = {
                            AdyenRequestPayload: state.data, 
                            AdyenResponsePayload: '', 
                            Reference: adyenSession.Reference
                        }
                        if (state.data) handlePaymentMethod(getAdyenCardDetails(session));
                    },
                    paymentMethodsConfiguration: {
                        applepay: applePayConfiguration
                    }
                });

                if (paymentContainer.current) {
                    const dropin = checkout.create('dropin').mount(paymentContainer.current);

                    if (adyenSession && adyenSession.AdyenResponsePayload && adyenSession.AdyenResponsePayload) {
                        try {
                            const result = JSON.parse(adyenSession.AdyenResponsePayload);
                            if (result) {
                                if (result.errorCode) dropin.setStatus('error', { message: result.message });
                                else {
                                    if (result.resultCode.toLowerCase() === 'authorised') history.push(window.isMarketplace ? `/marketplace/${window.marketplaceId}/${window.waypointID}/checkout-complete` : '/checkout-complete');
                                    if (result.resultCode.toLowerCase() === 'refused') dropin.setStatus('error', { message: result.refusalReason })
                                    else if (result.action) dropin.handleAction(result.action);
                                }
                            }
                        }
                        catch (e) {
                            dropin.setStatus('error', { message: 'Something went wrong.'});
                        }
                    }
                }
            }
            createCheckout();
        }
    }, [adyenSession]);

    return (
        <div className='adyen-payment-container' ref={paymentContainer}></div>
    )
}

AdyenDropIn.propTypes = {
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
	countryCode: PropTypes.string
}

export default AdyenDropIn;