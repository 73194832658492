/**
 * @ The external dependencies.
*/
import React, { useState, useEffect, useContext, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import '../../assets/css/_main-marketplace.css';
import { Context } from '../../store/state/marketplace/store';
import Loader from '../../components/loader/loader';
import { Aux, getGetG4IDFromConfig } from '../../lib/utility';
import ModalManager from '../../components/modal/modal-manager';
import { loadCustomer } from '../../store/state/customer/actions';
import { requestTableTopConfig, requestTableTopData } from '../../store/state/app/actions';
import { setLoadingResources, openModal } from '../../store/state/ui/actions';
import { getSessionStyle } from '../../lib/helpers/local-storage';
import * as Analytics from '../../lib/analytics-ga4';
import * as resourcesManager from '../../assets/resources/resources-manager';
import * as languagesManager from '../../assets/languages/languages-manager';
import { getLanguage, getCurrentLanguageDirection, setTextDirectionAttribute, setLanguageAttribute } from '../../lib/helpers/language';
import { MODAL_SUGGESTIONS } from '../../lib/constants';
import * as configsManager from '../../assets/configs/configs-manager';

const Marketplace = lazy(() => import('../marketplace/marketplace'));
const Categories = lazy(() => import('../category/categories'));
const Menu = lazy(() => import('../menu/menu'));
const Search = lazy(() => import('../search/search'));
const Product = lazy(() => import('../product/product'));
const CartEdit = lazy(() => import('../cart/cart-edit'));
const CustomerDetails = lazy(() => import('../marketplace/customer-details'));
const CheckoutComplete = lazy(() => import('../marketplace/checkout/checkout-complete'));
const OrderStatus = lazy(() => import('../marketplace/order-status/order-status'));
const InvalidMarketplace = lazy(() => import('../marketplace/invalid/invalid'));
const Map = lazy(() => import('../marketplace/map/map'));

const AppMarketplace = () => {

    const dispatch = useDispatch();
    const {airportState, fetchAirportInfo, setSelectedStore, selectedStore, setga, ga } = useContext(Context);
    const [waypoint, setWaypoint] = useState(null);
    const [resourcesLoaded, setResourcesLoaded] = useState(false);
    // const [ga, setga] = useState(null);

    const showSuggestionsModal = useSelector(state => state.cart.showSuggestionsModal);
    const cartPricingData = useSelector(state => state.cart.cartPricingData);
    const allProducts = useSelector(state => state.app.data && state.app.data.inventoryItemMains);

    useEffect(() => {
        fetchAirportInfo(window.marketplaceId);
        
        const splitPath = window.location.pathname.split('/');
        const storewaypoint = splitPath.length > 3 ? splitPath[3] : null;
        if (storewaypoint) {
            setWaypoint(storewaypoint);
            loadStoreInfo(storewaypoint);
        }
        else {
            configsManager.setupConfigs(window.marketplaceId, window.platformType, window.waypointID, () => {
                if (window.mpconfigs) {
                    setga(getGetG4IDFromConfig(window.mpconfigs));
                }
            });
        }

        //The setupResources in index.js is called async to allow Store Data + Config api calls to be kicked off. setupResources may or may not be completed at this point so it is called again showing spinner.
        //If the resources are already downloaded the browser should get them from cache so wont be a performance hit.
        dispatch(setLoadingResources(true));
        resourcesManager.setupResourcesMarketplace([window.marketplaceId], window.language, () => {
            setResourcesLoaded(true);
            dispatch(setLoadingResources(false));
        }, () => {
            setResourcesLoaded(true);
            dispatch(setLoadingResources(false));
        });
    }, []);
    useEffect(() => {
        if (ga) Analytics.initialize(ga);
    }, [ga]);
    useEffect(() => {
        if (airportState.airportinfo && airportState.airportinfo.currentAirport && waypoint) {
            let stores = [];
            if (airportState.airportinfo.currentAirport.terminals) {
                const terminals = airportState.airportinfo.currentAirport.terminals;
                terminals.forEach(t => {
                    if (t.waypoints) stores = [...stores, ...t.waypoints];
                })
            }
            const store = stores.find(s => s.waypointID === waypoint);
            if (store) setSelectedStore(store);
        }
    }, [airportState.airportinfo]);
    useEffect(() => {
        if (showSuggestionsModal && allProducts) {
            const showSuggestions = cartPricingData && cartPricingData.upsaleItems.length > 0
            if (showSuggestions) {
                dispatch(openModal({
                    type: MODAL_SUGGESTIONS,
                    data: {
                        upsaleItems: cartPricingData.upsaleItems
                    }
                }))
            }
        }
    }, [showSuggestionsModal])

    const loadStoreInfo = (waypoint) => {
        //window.kobp = 'grab_tabletop_' + waypoint;
        const style = getSessionStyle(window.kobp);
        window.waypointID = waypoint;
        window.platformType = 9;

        const styleWaypointIDs = [waypoint];
        window.styleWaypointIDs = styleWaypointIDs;
        //Download languages.js, set window.language, then download resources.{language}.js
        const setupLanguagesCallback = () => {
            window.language = getLanguage();
            setTextDirectionAttribute(getCurrentLanguageDirection());
            setLanguageAttribute(window.language.substring(0, 2));
            resourcesManager.setupResourcesMarketplace([window.marketplaceId], window.language);
        };
        languagesManager.setupLanguages(window.styleWaypointIDs, setupLanguagesCallback, setupLanguagesCallback);

        configsManager.setupConfigs(window.marketplaceId, window.platformType, window.waypointID);

        // window.resources = { spinners: { loading_text: 'Loading' } };

        dispatch(loadCustomer());

        dispatch(requestTableTopConfig({ performOrderCalc: false }));
        dispatch(requestTableTopData());
    }
    const isLandingPagePath = () => {
        return (
            window.location.pathname === `/marketplace/${window.marketplaceId}` || 
            window.location.pathname.includes('/checkout-complete') || 
            window.location.pathname.includes('/order-status')
        );
    }

    const title = window.location.pathname === `/marketplace/${window.marketplaceId}` ? `Marketplace ${window.marketplaceId.toUpperCase()} - Home` :
                window.location.pathname === `/marketplace/${window.marketplaceId}/${window.waypointID}/categories` ? `Marketplace ${window.marketplaceId.toUpperCase()} - ${selectedStore.store.storeName} - Categories` : 
                window.location.pathname === `/marketplace/${window.marketplaceId}/${window.waypointID}/menu` ? `Marketplace ${window.marketplaceId.toUpperCase()} - ${selectedStore.store.storeName} - Menu` : 
                window.location.pathname.includes('/menu/') && window.marketplaceId ? `Marketplace ${window.marketplaceId.toUpperCase()} - ${selectedStore.store.storeName} - Menu Item` : 
                window.location.pathname === `/marketplace/${window.marketplaceId}/${window.waypointID}/cart` ? `Marketplace ${window.marketplaceId.toUpperCase()} - ${selectedStore.store.storeName} - Cart` : 
                window.location.pathname === `/marketplace/${window.marketplaceId}/${window.waypointID}/checkout-complete` ? `Marketplace ${window.marketplaceId.toUpperCase()} - ${selectedStore.store.storeName} - Checkout Complete` : 
                `Marketplace ${window.marketplaceId.toUpperCase()}`;

    const description = selectedStore && selectedStore.store ? `${selectedStore.store.storeName} at ${window.marketplaceId}` : window.marketplaceId ? `Marketplace ${window.marketplaceId}` : 'Invalid Marketplace';
    return (
        !selectedStore && !JSON.parse(window.sessionStorage.getItem('selectedstore')) && !isLandingPagePath() && window.marketplaceId ?
        <Redirect to={`/marketplace/${window.marketplaceId}`} /> :
        <div className='wrapper marketplace-container'>
            {   resourcesLoaded ? 
                <React.Fragment>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content={description} /> 
                        {!window.marketplaceId && (<meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />)}
                    </Helmet>
                    <Aux>
                        <Switch>
                        <Suspense fallback={<div className='wrapper'><Loader title={window.resources.spinners.loading_text} /></div>}>
                            <Route exact path='/marketplace' render={() => <InvalidMarketplace />} />
                            <Route exact path='/marketplace/:id' render={() => <Marketplace />} />
                            <Route exact path='/marketplace/:id/map' render={() => <Map />} />
                            <Route path='/marketplace/:id/:store/categories' render={() => <Categories />} />
                            <Route path='/marketplace/:id/:store/search' render={() => <Search />} />
                            <Route path='/marketplace/:id/:store/menu' exact render={() => <Menu />} />
                            <Route path='/marketplace/:id/:store/menu/:pid' render={() => <Product />} />
                            <Route path='/marketplace/:id/:store/cart' exact render={() => <CartEdit />} />
                            <Route path='/marketplace/:id/:store/details' exact render={() => <CustomerDetails />} />
                            <Route path='/marketplace/:id/:store/checkout-complete' exact render={() => <CheckoutComplete />} />
                            <Route path='/marketplace/:id/:store/order-status/:orderid/:kobp' render={() => <OrderStatus /> } /></Suspense>
                        </Switch>

                        <ModalManager key='modal' />
                    </Aux>
                </React.Fragment> : null
            }
        </div>
    )
}

export default AppMarketplace;