/**
 * @ The external dependecies.
 */
import { createAction } from 'redux-actions';

/**
 * Clears the cart.
 *
 * @return {Object}
 */
export const clearCart = createAction('cart/clear-cart');

export const clearCartPartial = createAction('cart/clear-cart-partial');

/**
 * Loads current cart information from stored json.
 *
 * @return {Object}
 */
export const loadCart = createAction('cart/load-cart');

/**
 * Starts an add to cart.
 *
 * @return {Object}
 */
export const startAddToCart = createAction('cart/start-add-to-cart');

/**
 * Adds to the cart.
 *
 * @return {Object}
 */
export const addToCart = createAction('cart/add-to-cart');

/**
 * Starts a remove from cart.
 *
 * @return {Object}
 */
export const startRemoveFromCart = createAction('cart/start-remove-from-cart');

/**
 * Removes a from cart.
 *
 * @return {Object}
 */
export const removeFromCart = createAction('cart/remove-from-cart');

/**
 * Update the price modifiers.
 *
 * @return {Object}
 */
export const updateModifiers = createAction('cart/update-modifiers');

/**
 * Update delivery info.
 *
 * @return {Object}
 */
export const startDeliveryUpdate = createAction('cart/start-delivery-update');

/**
 * Update delivery info.
 *
 * @return {Object}
 */
export const deliveryUpdate = createAction('cart/delivery-update');

/**
 * Update the donation amount.
 *
 * @return {Object}
 */
 export const startDonationAmountUpdate = createAction('cart/start-donation-amount-update');

 /**
 * Update the donation amount.
 *
 * @return {Object}
 */
export const donationAmountUpdate = createAction('cart/donation-amount-update');

/**
 * Update promotion info (marketplace).
 *
 * @return {Object}
 */
export const startPromotionUpdate = createAction('cart/start-promotion-update');

/**
 * Update promotion info (marketplace).
 *
 * @return {Object}
 */
export const promotionUpdate = createAction('cart/promotion-update');

/**
 * Update the tip amount.
 *
 * @return {Object}
 */
export const startTipAmountUpdate = createAction('cart/start-tip-amount-update');

/**
 * Update the tip percent.
 *
 * @return {Object}
 */
export const startTipPercentageUpdate = createAction('cart/start-tip-percentage-update');

/**
 * Update the tip amount.
 *
 * @return {Object}
 */
export const tipAmountUpdate = createAction('cart/tip-amount-update');

/**
 * Update the tip percentage.
 *
 * @return {Object}
 */
export const tipPercentageUpdate = createAction('cart/tip-percentage-update');

/**
 * Update cart product.
 *
 * @return {Object}
 */
export const updateCartProduct = createAction('cart/update-cart-product');

/**
 * Update the cart total price.
 *
 * @return {Object}
 */
export const updateCartTotal = createAction('cart/update-cart-total');

/**
 * Update the cart products.
 *
 * @return {Object}
 */
export const updateCartProducts = createAction('cart/update-cart-products');

/**
 * Sets the edit product.
 *
 * @return {Object}
 */
export const setEditProduct = createAction('cart/set-edit-product');

/**
 * Recives cart data.
 *
 * @return {Object}
 */
export const reciveCartData = createAction('cart/recive-cart-data');

/**
 * Starts cart update.
 *
 * @return {Object}
 */
export const startCartUpdate = createAction('cart/start-cart-update');

export const cartUpdateInitiated = createAction('cart/cart-update-initiated');

export const cartUpdateCompleted = createAction('cart/cart-update-completed');

/**
 * CLears edit product.
 *
 * @return {Object}
 */
export const clearEditProduct = createAction('cart/clear-edit-product');

/**
 * Clears promotion.
 *
 * @return {Object}
 */
export const clearPromotion = createAction('cart/clear-promotion');

/**
 * Starts cart save.
 *
 * @return {Object}
 */
export const startCartSave = createAction('cart/start-cart-save');

export const cartSaveCompleted = createAction('cart/cart-save-completed');

/**
 * Recives order confirmation data.
 *
 * @return {Object}
 */
export const receiveOrderConfirmationData = createAction('cart/recive-order-confirmation');

export const acknowledgeAlcohol = createAction('cart/acknowledge-alcohol');

export const donationsModalShown = createAction('cart/donations-modal-shown');

export const tipsModalShown = createAction('cart/tips-modal-shown');

export const updatePrevAddons = createAction('cart/update-prev-addons');

export const updateCustomerDetails = createAction('cart/update-customer-details');

export const updateEmail = createAction('cart/update-email');

export const updatePhone = createAction('cart/update-phone');

export const updateEmployeeID = createAction('cart/update-employee-id');

export const updatePaymentDetails = createAction('cart/update-payment-details');

export const updateSpreedlyTransactionToken = createAction('cart/update-spreedly-transaction-token');

export const updateMode = createAction('cart/update-mode');

export const updateName = createAction('cart/update-name');

export const updateSplitAmount = createAction('cart/update-split-amount');

export const updateTableData = createAction('cart/update-table-data');
export const updateLoyaltyAmount = createAction('cart/update-loyalty-amount');
